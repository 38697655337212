import React from "react"
import {
  TiSocialTwitter,
  TiSocialLinkedin,
  TiSocialFacebook,
  TiSocialInstagram,
  TiMail,
} from "react-icons/ti"

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-social">
          <div className="footer-social-text">Follow Us</div>
          <div className="footer-social-icons">
            <a
              target="_blank"
              href="mailto:info@mahmoudallam.me"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="mail-icon">
                <TiMail className="footer-social-icon" />
              </span>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/mahmoud0allam"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="tw-icon">
                <TiSocialTwitter className="footer-social-icon" />
              </span>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/mahmoud0allam/"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="insta-icon">
                <TiSocialInstagram className="footer-social-icon" />
              </span>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/mahmoud0allam/"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="li-icon">
                <TiSocialLinkedin className="footer-social-icon" />
              </span>
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/mahmoud0allam"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="fb-icon">
                <TiSocialFacebook className="footer-social-icon" />
              </span>
            </a>
          </div>
        </div>
        <div>© {new Date().getFullYear()} all rights reserved.</div>
      </div>
    </footer>
  )
}
export default Footer
